@value color-primary, color-pricing-grey, color-button-border, color-white from "~@properly/components/lib/common/styles/colors.module.css";
@value graphik from "~@properly/components/lib/common/styles/typography.module.css";
:local(.pricing__thankyouhead) {
  composes: text-l3m from global;
  text-align: center;
}

:local(.pricing__thankyoutxt) {
  composes: text-l6 from global;
  text-align: center;
}

.pricing__messagered {
  margin-bottom: 5px;
  height: 38px;
  background-color: color-primary;
  border-radius: 3px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pricing__messagered-wrap {
  margin-top: 5px;
  padding: 0 5px;
}

.pricing__message-close {
  height: 24px;
  width: 24px;
  margin-top: 6px;
  margin-left: 6px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

:local(.pricing__message-txt) {
  composes: text-l5 from global;
  color: color-white;
}
:local(.pricing__message-txt) span {
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.pricing__planbox {
  border: 1px solid color-button-border;
  padding: 16px;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 500px;
  background-color: color-white;
}

.pricing__planbox-wrap {
  border: 1px solid transparent;
  border-radius: 5px;
}

.pricing__planbox-top {
  font-family: graphik;
  visibility: hidden;
  height: 34px;
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  flex-direction: column;
  font-size: 15px;
  font-weight: 600;
}

.pricing__planbox-wrap--active {
  border: 2px solid color-primary;
}
.pricing__planbox-wrap--active .pricing__planbox {
  border: 1px solid transparent;
}
.pricing__planbox-wrap--active .pricing__planbox-top {
  visibility: visible;
  background-color: color-primary;
  color: white;
  text-transform: uppercase;
}

.pricing__planbox--small {
  min-height: 500px;
}

.pricing__featurelist--slim .pricing__planbox-txt:first-child {
  font-size: 16px;
}

.pricing__planbox-upper {
  flex: 1;
}

:local(.pricing__planbox-name) {
  padding-bottom: 8px;
  composes: text-l5m from global;
}

.pricing__planbox-price {
  font-family: graphik;
  font-size: 46px;
  color: color-primary;
  margin-top: 8px;
  position: relative;
  display: inline-block;
}

.pricing__planbox-price-wrap {
  margin-bottom: 16px;
}

.pricing__planbox-contactus {
  font-family: graphik;
  font-size: 30px;
  color: color-primary;
  padding: 26px 0;
  margin-bottom: 16px;
}

.pricing__planbox-price-dollar {
  position: absolute;
  left: -4px;
  top: 0;
  transform: translate(-100%, 5px);
}

.pricing__planbox-price-usd {
  position: absolute;
  right: -2px;
  bottom: 0;
  transform: translate(100%, -5px);
}

:local(.pricing__planbox-smalltxt) {
  color: color-pricing-grey;
  composes: text-l6 from global;
}

.pricing__planbox-smalltxt--smaller {
  font-size: 12px;
}

:local(.pricing__planbox-txt) {
  composes: text-l6 from global;
  margin-bottom: 10px;
}

:local(.pricing__infotxt) {
  color: color-pricing-grey;
  composes: text-l7 from global;
}

.pricing__mobilewrap .pricing__row {
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
}
.pricing__mobilewrap .pricing__item {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  min-width: 0;
}
.pricing__mobilewrap .pricing__planbox-wrap--active {
  margin-top: 34px;
}
.pricing__mobilewrap .pricing__item--reduced {
  margin-bottom: 0px;
}

.pricing__row {
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  flex-direction: row;
}

.pricing__row--full {
  margin-left: -12px;
  margin-right: -12px;
}

.pricing__row--right {
  justify-content: flex-end;
}

.pricing__item {
  padding-left: 12px;
  padding-right: 12px;
  min-width: 25%;
  width: 25%;
}

.pricing__item--reduced {
  margin-bottom: -40px;
}

.pricing__item--big {
  min-width: none;
  width: auto;
}

.pricing__plans {
  padding: 15px;
  min-height: 600px;
  position: relative;
}

.pricing__plans--nopadding {
  padding: 0;
}

:local(.pricing__headbottom) {
  margin-top: 24px;
  composes: text-l5m from global;
}

.pricing__headbottom--low {
  margin-top: 8px;
}

:local(.pricing__bottomtxt) {
  composes: text-l6 from global;
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}
:local(.pricing__bottomtxt) img {
  padding-right: 8px;
  min-height: 16px;
  min-width: 16px;
  max-width: 16px;
  max-height: 16px;
  box-sizing: content-box;
}

.pricing__planbox-wrap--slim .pricing__planbox-name {
  padding-top: 0px;
  margin-top: -5px;
  text-transform: inherit;
}
.pricing__planbox-wrap--slim .pricing__planbox-name {
  margin-bottom: 5px;
}

.pricing__planbox--slim {
  min-height: 0;
}

.pricing__divider {
  border-bottom: 1px solid #98A0A9;
  margin: 15px auto;
  width: 120px;
}
