@value color-primary from '~@properly/components/lib/common/styles/colors.module.css';

:local(.checkout__input-label){
    composes: text-l8m from global;
    padding-bottom: 6px;
    display: block;
}

.checkout__input-man{
    color: color-primary;
}
