@value color-checklist-btn-txt, color-orange, color-no-result, color-dark-grey-darker, color-background-dark, color-loading-txt, color-text, color-background, color-primary, color-grey, color-white from '~@properly/components/lib/common/styles/colors.module.css';
@value space-small, space-tiny, space-base, space-large, page-max-width from '~@properly/components/lib/common/styles/sizes.module.css';

:local(.section){
  height: 100px;
  border: 1px solid red;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px 4px 0 4px;
  user-select: none;
}

.checklist__clone-overlay{
  background: color-white;
  opacity: 0.5;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

:local(.checklist__clone-noresult){
  display: flex;
  height: 165px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  composes: label from global;
  color: color-no-result;
}

.checklist__tasks{
  margin-top: space-base;
  margin-bottom: space-base;
}
:local(.checklist__clone-box){
  composes: input-error from global;
  color: color-loading-txt;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  align-items: center;
}
.checklist__clone-box-txt{
  margin-right: 8px;
}
.checklist__clone-box-check{
  margin-right: 16px;
}

.checklist__upload_input {
  height: 0px;
  width: 0px;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
}

div.checklist__popover {
  position: relative !important;
  min-width: 200px;
  border: none;
  padding: 0;
  box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
}
div.checklist__popover :global(.popover-content) {
  padding: 0 !important;
}

div.checklist__popover--sidebar {
  position: absolute !important;
  margin-top: -15px !important;
  margin-left: 34px;
}
div.checklist__popover--sidebar :global(.arrow) {
  left: 26px !important;
}

div.checklist__popover--steps {
  position: absolute !important;
  margin-left: -12px;
  margin-top: 17px !important;
}
div.checklist__popover--steps :global(.arrow) {
  left: 50% !important;
}

div.checklist__popover--textslide{
  position: absolute !important;
}
:local(.checklist__popoveritem) {
  padding: 10px 16px;
  composes: input from global;
  color: color-text;
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
}
:local(.checklist__popoveritem):after {
  content: " ";
  position: absolute;
  bottom: 0;
  height: 1px;
  background: rgba(29, 39, 48, 0.12);
  left: 0;
  right: 0;
}
:local(.checklist__popoveritem):last-child:after {
  content: "";
  display: none;
}
:local(.checklist__popoveritem) img {
  margin-right: 12px;
}
.checklist__topalign{
  display: flex;
  align-items: center;
}
:local(.checklist__topalign-title){
  max-width: 200px;
  composes: cut-txt from global;
}
.checklist__topalign--col{
  flex-direction: column;
}
:local(.checklist__topalign-txt){
  composes: input-error from global;
  color: color-no-result;
}

.checklist__sidebar-btn{
  height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checklist__popoverwrap{
  position: relative;
}

.checklist__pin-actual{
  width: 64px;
  height: 64px;
  opacity: 1;
  justify-content: center;
  position: relative;
  transition: transform 0.2s;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
     -khtml-user-select: none; /* Konqueror */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

}
.checklist__pin-actual--positioned{
  cursor: move;
}
.checklist__pin-actual--dragging{
  opacity: 0.9
}
.checklist__pin-actual--top{
  transform: translateY(-100%) translateX(-50%); /* fix position */
}
.checklist__pin-actual--left {
  transform: translateY(-50%) translateX(-100%) rotate(-90deg);
}
.checklist__pin-icon--left {
  transform: rotate(90deg);
}

.checklist__pin-actual--bottom {
  transform: translateY(0) translateX(-50%) rotate(180deg);
}
.checklist__pin-icon--bottom {
  transform: rotate(-180deg);
}

.checklist__pin-actual--right {
  transform: translateY(-50%) translateX(0) rotate(90deg);
}
.checklist__pin-icon--right {
  transform: rotate(-90deg);
}
.checklist__pin-actual--small{
  height: 48px;
  width: 64px;
}
.checklist__pin-outer{
  position: absolute;
}
:local(.checklist__trash){
  position: absolute;
  height: 100px;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  background-repeat: no-repeat;
  display: flex;
  composes: normal from global;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  color: color-white;
}
.checklist__trash-inner{
  padding: 38px 10px;
  display: flex;
}
.checklist__pin{
  position: absolute;
  height: 2px;
  border-radius: 10px;
  width: 2px;
}
.checklist__pin--hover{
  opacity: 0.5;
}
.checklist__fixedlayer{
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.checklist__image{
  position: relative;
  overflow: hidden;
  background-size: cover;
  padding-bottom: 56.25%;
}
.checklist__image-actual{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  background-size: cover;
}
:local(.checklist__image-replace) {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  right: 8px;
  top: 8px;
  background: rgba(51, 51, 51, 0.8);
  border-radius: 17px;
  height: 32px;
  padding: 8px;
  composes: input from global;
  color: color-white;
  display: flex;
  align-items: center;
}
:local(.checklist__image-replace) > span {
  margin-left: 4px;
}
.checklist__image-pin-overlay{
  background: rgba(29,39,48,0.35);
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 22;
}
:local(.checklist__image-info){
  background: rgba(29,39,48,0.45);
  border-radius: inherit;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 22;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  composes: normal from global;
  color: color-white;
}
.checklist__image-info-txt{
  max-width: 250px;
  text-align: center;
}

.checklist__maxwidth{
  max-width: 800px;
  margin: 0 auto;
}

.checklist__needsverify{
  background: color-background;
  border-radius: 2px;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checklist__needsverify-left{
  display: flex;
  align-items: center;
}
:local(.checklist__needsverify-txt){
  margin-left: 12px;
  composes: text-type-small from global;
  color: color-loading-txt;
}

:local(.checklist__btn){
  border: 1px solid color-dark-grey-darker;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  color: color-white;
  composes: input from global;
  font-weight: 500;
  flex: 1;
  position: relative;
  cursor: pointer;
  user-select: none;
}

:local(.checklist__btn) > span {
  display: flex;
  align-items: center;
  opacity: 0.5;
}

.checklist__btn--first{
  margin-right: 4px;
}
.checklist__btn--last{
  margin-left: 4px;
}
.checklist__btn-txt{
  margin-left: 8px;
}
.checklist__btn-wrap{
  margin-bottom: 8px;
  height: 44px;
  display: flex;
  position: relative;
}

@media print {
  .checklist__btn-wrap{
    display: none;
  }
}

.checklist__box-wrap{
  margin-bottom: 8px;
}

.checklist__topbar{
  margin-bottom: space-small;
  display: flex;
}

.checklist__topdes{
  display: flex;
}
.checklist__topdes-right{
  margin-left: 45px;
  flex: 1;
}
.checklist__createchecklist{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid color-dark-grey-darker;
  position: relative;
}
.checklist__createchecklist-spacer--minheight{
  min-height: 79px;
}
.checklist__createchecklist-spacer{
  padding-bottom: 56.25%;
  width: 100%;
}
.checklist__createchecklist-abs{
  position: absolute;
  top: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  cursor: pointer;
}

.checklist__topbar-icon{
  margin: 0 space-tiny;
  cursor: pointer;
}

.checklist__overlay-white{
  background: color-white;
  opacity: 0.76;
  position: fixed;
  z-index: 10;
  display: none;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.checklist__overlay-white--show .checklist__overlay-white{
  display: block;
}
.checklist__dropbox{
  z-index: 20;
  position: relative;
}

.checklist__placeholder {
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  background: rgba(0, 0, 0, 0.12);
}
.checklist__placeholder.text {
  background: #FFF;
  padding-bottom: 0;
  height: 49px;
}
.checklist__placeholder.indicate {
  border-left: 2px solid color-primary;
}

.checklist__topbar-section{
  flex: 1;
}
.checklist__topbar-section--right{
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex: inherit;
}

.bubble{
  height: 56px;
  width: 56px;
  background: color-orange;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
:local(.bubble-outer) {
  cursor: pointer;
  user-select: none;
  composes: input-error from global;
  color: color-loading-txt;
  padding: 0 12px;
  margin-bottom: 8px;
  max-width: 80px;
  text-align: center;
}
:local(.bubble-outer) span {
  display: inline-block;
  margin-left: -12px;
  margin-right: -12px;
  margin-top: 8px;
}
.bubble__wrap{
  display: flex;
}
.bubble__container{
  padding: 18px;
}

.pin{
  position: absolute;
  width: 64px;
  height: 48px;
  background-size: contain;
  opacity: 1;
  background-repeat: no-repeat;
}
.pin--opacity{
  opacity: 0.7;
}

.checklist__container--isError{
   display: flex;
   align-items: center;
   justify-content: center;
   height: 400px;
   width: 100% ;
}
