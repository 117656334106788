@value color-header, color-border, color-white, color-primary, color-primary-hover, color-text from "~@properly/components/lib/common/styles/colors.module.css";
@value graphik from "~@properly/components/lib/common/styles/typography.module.css";
.wizard-h1 {
  color: color-header;
  font-family: graphik;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  margin-top: 30px;
}

.wizard-h1--passwordreset {
  margin-top: 48px;
  margin-bottom: 12px;
}

.wizard-h1--login {
  margin-top: 48px;
  margin-bottom: 32px;
}

.wizard-h1--lesstop {
  margin-top: 0px;
}

.wizard-p {
  line-height: 1.6em;
  text-align: center;
  margin: 30px 0;
}

.wizard-reset-password {
  max-width: 450px;
}

.wizard-container {
  background: color-white;
  margin: auto;
  max-width: 500px;
  min-height: 300px;
  position: relative;
  width: 100%;
}

.wizard-container--mobile {
  width: 100% !important;
}

.wizard-container--big {
  width: 1000px;
  max-width: none;
}

.wizard-inner {
  margin: auto;
  padding-bottom: 10px;
  width: 90%;
}
.wizard-inner .wizard-h1, .wizard-inner .wizard-p {
  text-align: center;
}

.wizard-loading {
  display: block;
  min-height: 300px;
  position: relative;
}

.wizard-loading-is-waiting {
  display: block;
}

.wizard-loading-status {
  display: block;
  width: 100%;
  text-align: center;
  padding-top: 130px;
}

.tos__link {
  color: color-primary;
  cursor: pointer;
}

:local(.forgot-password) {
  text-align: center;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  composes: input from global;
  color: color-text;
}

.signup-complete-image {
  display: block;
  margin: auto;
  width: 65%;
}

.signup-download-logo {
  display: block;
  margin: 30px auto;
}

.username {
  font-size: 22px;
}

.skip {
  cursor: pointer;
}

.signup-app-store-description {
  color: #868F9A;
  display: block;
  margin-bottom: 12px;
}

.center {
  text-align: center;
}

@media (min-width: 750px) {
  .signup__text{
    display:none;
  }
}
/* TODO: max width to be imported from size.module.css */
@media (max-width: 750px) {
.signup__text{
  display:flex;
  justify-content: center;
  margin-top: 2em;
}
}

.signup__link{
  text-decoration: none;
  color: color-primary;
  cursor: pointer;
  display:flex;
  justify-content: center;
}

:local(.wizard__clicktxt) {
  composes: text-type-large from global;
  color: color-primary;
  cursor: pointer;
}

.wizard__clicktxt-wrap {
  width: 100%;
}

.signup__partnerlogo {
  padding: 42px 0 50px 0;
  display: flex;
  justify-content: center;
}

.signup__partnerlogo--mobile {
  padding: 10px;
}

.signup__partnerbox--top {
  order: 1;
}

.signup__partnerbox--bottom {
  order: 2;
}

.signup__partnerbox {
  width: 380px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 35px;
  text-align: center;
}

.signup__partnerbox-wrap {
  display: flex;
  justify-content: center;
}

.signup__partnerbox-wrap--mobile {
  flex-direction: column;
}
.signup__partnerbox-wrap--mobile .signup__partnerbox {
  width: 100%;
  margin: 0;
  padding: 0 20px;
}
.signup__partnerbox-wrap--mobile .signup__partnerlist {
  margin-top: 10px;
}

:local(.signup__partnerlist) {
  composes: text-l6 from global;
  margin: 16px 0;
  margin-top: 20px;
  padding-left: 10px;
  text-align: left;
}
:local(.signup__partnerlist) li {
  margin-bottom: 8px;
}

.signup__partnerimg {
  max-width: 100%;
}

/*** These are for Bookingcom quick edits 21/06/2017 #BookingcomSignupFix ***/
.bookingcom_title {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}

.bookingcom_description {
  font-size: 18px;
  line-height: 24px;
}

.bookingcom_partnerlist_title_mobile {
  text-align: left;
  font-weight: 500;
  margin-left: 0;
  font-size: 17px;
  margin-bottom: 8px;
  margin-top: 36px;
  display: block;
}

.bookingcom_is_not_mobile_view {
  display: none;
}

:local(.signup__partnerlist_mobile) {
  display: block;
  composes: text-l6 from global;
  margin: 16px 0;
  margin-top: 20px;
  padding-left: 10px;
  text-align: left;
}
:local(.signup__partnerlist_mobile) li {
  margin-bottom: 8px;
}

/***************************************************************/
