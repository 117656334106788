@value color-profile-background, color-grey, color-black, color-border-search, color-primary, color-radio-border-idle, color-white from '~@properly/components/lib/common/styles/colors.module.css';

.reports__boxrow{
    display: flex;
    flex-direction: row;
}

.reports__boxele{
    flex: 1;
}
.reports__boxele--spacer{
    margin-right: 45px;
}

.reports__spacer{
    background: color-profile-background;
    height: 1px;
    width: 100%;
}
.reports__spacer--noline{
    background: transparent;
}
.reports__spacer--default{
    margin-top: 24px;
}

.reports__selectb{
    border: 1px solid color-radio-border-idle;
    border-radius: 2px;
    width: 266px;
    height: auto;
    position: absolute;
    margin-top: 4px;
    background: color-white;
    z-index: 1000;
    overflow: hidden;
}

.reports__selectb--big{
    width: 738px;
    height: 400px;
}

:local(.reports__selectb-title){
    composes: text-l5 from global;
    color: color-primary;
    padding: 16px 16px 0 16px;
}
.reports__selectb-title--low{
    padding-top: 8px;
}
.reports__selectb-title--full{
    padding: 16px;
}

.reports__cellitem{
    margin-top: 6px;
    display: inline-block;
    word-wrap: break-word;
    max-width: 100%;
}
.reports__cellitem--primary{
    color: color-primary;
}

.reports__selectb-header{
    height: 48px;
    position: relative;
    border-bottom: 1px solid color-border-search;
    cursor: pointer;
    user-select: none;
}
:local(.reports__selectb-header-inner) {
    padding: 14px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    composes: text-l6 from global;
  }
:local(.reports__selectb-header-inner) span {
    position: relative;
    width: 100%;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

:local(.reports__selectb-ele) {
    height: 35px;
    position: relative;
    composes: input-error from global;
    color: color-black;
    padding: 6px 16px 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
}
:local(.reports__selectb-ele) span {
    position: relative;
    width: 100%;
    padding-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.reports__selectb-ele-divider {
    background: rgba(29,39,48,0.12);
    height: 1px;
    position: absolute;
    bottom: -1px;
    left: 16px;
    right: 0;
}
.reports__selectb-ele-divider-full {
    background: rgba(29,39,48,0.12);
    height: 1px;
    position: relative;
    width: 100%
}

.reports__dateswrap{
    display: flex;
    justify-content: center;
    position: relative;
}

.reports__dates-header{
    padding: 14px 14px 0 14px;
    height: 52px;
}

.reports__dates-header-inner{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid color-border-search;
    text-align: center;
}

:local(.reports__dates-header-inner-ele){
    padding: 10px 12px;
    display: inline-block;
    height: 100%;
    composes: text-l6 from global;
    color: color-grey;
    cursor: pointer;
    user-select: none;
}

.reports__dates-header-inner-ele--active{
    color: color-primary;
}

.reports__dateswrap-overlay{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: color-white;
    z-index: 100;
    opacity: 0.4;
}

.reports__wraptopbottom{
    display: flex;
    flex-direction: column;
    height: 100%;
}
.reports__wrapbottom{
    flex: 1;
    position: relative;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
}
.reports__wrapbottom-pre{
    position: relative;
    flex: 1;
}

.reports__wrapbottom-inner{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.tablewrap{
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-top: 1px solid color-profile-background;
    border-left: 1px solid color-profile-background;
    position: relative;
}
.tablewrap__afterbottom{
    height: 2px;
    width: 100%;
    border-top: 1px solid color-profile-background;
    background: color-white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    z-index: 1;
}
.tablewrap__afterright{
    width: 2px;
    height: 100%;
    border-left: 1px solid color-profile-background;
    background: color-white;
    position: absolute;
    top: 1px;
    bottom: 1px;
    right: -1px;
    z-index: 1;
}

.table{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.table thead tr{
    border-right: 0;
}
.table th {
    color: color-grey;
    text-align: left;
}
.table td {
    color: color-black;
}
.table tr {
    display: flex;
}

:local(.report__table-cell){
    min-height: 32px;
    border-right: 1px solid color-profile-background;
    border-bottom: 1px solid color-profile-background;
    max-width: 200px;
    min-width: 200px;
    display: block;
    padding: 8px;
    composes: text-l7 from global;
}

.report__table-cell--cursor{
    cursor: pointer;
}

.reports__talbe-body{
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    display: block;
}

.reports__dropwrap{
    position: relative;
}

.reports__colortilewrap {
    display: inline-block;
    margin-top: 6px;
}
.reports__colortilewrap div {
    margin-bottom: 14px;
}
.reports__colortilewrap div:last-child {
    margin-bottom: 0;
}

.reports__colortile{
    width: 100px;
}

.reports__topmaxwidth{
    max-width: 1216px;
}

.reports__textstackwrap > div {
    height: 24px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    box-sizing: content-box;
    position: relative;
  }
  .reports__textstackwrap > div:after {
    position: absolute;
    left: -8px;
    right: -8px;
    height: 1px;
    background: color-profile-background;
    bottom: 4px;
    content: " ";
  }
  .reports__textstackwrap > div:last-child {
    padding-bottom: 0;
  }
  .reports__textstackwrap > div:last-child:after {
    display: none;
  }

.reports__textstackwrap--flex div {
    height: auto;
    min-height: 24px;
}

.reports__warning{
    margin-top: 18px;
}
