@value space-base, space-large from '~@properly/components/lib/common/styles/sizes.module.css';
@value color-grey, color-black, color-primary, color-profile-background, color-red, color-orange from '~@properly/components/lib/common/styles/colors.module.css';

.contact__profile-pic{
  height: 124px;
  width: 124px;
  padding-right: 12px;
  box-sizing: content-box;
  flex-shrink: 0;
}

.contact__top{
  margin: -48px -32px -44px;
  padding: 64px 32px 76px;
  background: white;
}

.contact__top-info{
  display: flex;
  flex: 1;
}

.contact__profile-main {
  flex-grow: 1;
}

.contact__notinvited{
  max-width: 335px;
}

.contact__send-job {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 17rem;
}

:local(.contact__resent){
  composes: input from global;
  color: color-orange;
  text-decoration: underline;
  cursor: pointer;
}

.contact__maxwidth{
  max-width: 515px;
}

.contact__dots{
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.contact__modal-btncenter{
  margin-top: 4px;
  display: flex;
  justify-content: center;
}

.contact__modal-footer{
  padding-top: space-base;
  display: flex;
}

:local(.contact__modal-error){
  composes: input-error from global;
  color: color-red;
  text-align: center;
}

.contacts__modal-loading{
  position: relative;
  height: 180px;
}
