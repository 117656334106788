@value color-red, color-black, color-loading-txt from '~@properly/components/lib/common/styles/colors.module.css';


.todo__nextsevenok{
    height: 148px;
    box-sizing: content-box;
    padding: 44px 0;
}

.todo__maxwith{
    max-width: 1216px;
    margin: 0 auto;
}


