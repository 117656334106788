@value graphik: 'Graphik Web', Helvetica, Arial, sans-serif;

@value color-text: '#29333D';
@value color-item-description: '#999B9E';

@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 100;
  src: url('../fonts/Graphik-Thin.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/Graphik-Extralight.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/Graphik-Light.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Graphik-Regular-Web.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Graphik-Medium-Web.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Graphik-Semibold.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Graphik-Bold.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Graphik-Black.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'Graphik Web';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/Graphik-Super.woff') format('woff');
}


:global .heading {
  font-family: graphik;
  font-size: 28px;
  font-weight: 500;

  line-height: 48px;
}

:global .page-title {
  font-family: graphik;
  font-size: 18px;
  font-weight: 500;

  line-height: 18px;
}

:global .subheading {
  font-family: graphik;
  font-size: 21px;
  font-weight: 500;

  line-height: 24px;
}

:global .text-type-title-2 {
  font-family: graphik;
  font-size: 32px;
  color: color-text;
  letter-spacing: 0px;
  line-height: 36px;
}

:global .normal {
  font-family: graphik;
  font-size: 16px;
  font-weight: 400;

  line-height: 24px;
}

:global .small {
  color: black;
  font-family: graphik;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 50px;
}


:global .xsmall {
  font-family: graphik;
  font-size: 10px;
  line-height: 12px;
}

:global .label{
  font-family: graphik;
  font-size: 18px;
  line-height: 24px;
}

:global .input{
  font-family: graphik;
  font-size: 14px;
  line-height: 18px;
}

:global .input-error{
  font-family: graphik;
  font-size: 12px;
  line-height: 16px;
}

:global .item-title {
  color: black;
  font-family: graphik;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
:global .item-description {
  color: color-item-description;
  font-family: graphik;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

:global .text-type-small {
  font-family: graphik;
    font-size: 14px;
    color: color-text;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 18px;
}

:global .font-graphik {
  font-family: graphik;
}

:global .text-type-large {
    font-family: graphik;
    font-size: 18px;
    color: color-text;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 24px;
    max-width: 440px;
}

:global .text-type-regular {
    font-family: graphik;
    font-size: 16px;
    color: color-text;
    font-weight: normal;
    letter-spacing: 0px;
    line-height: 22px;
    max-width: 440px;
}

:global .text-tiles-calendar{
  font-family: graphik;
  font-size: 11px;
  line-height: 14px;
}

:global .cut-txt{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:global .text-l5m{
  font-family: graphik;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
}

:global .text-l5{
  font-family: graphik;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

:global .text-l6m{
  font-family: graphik;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

:global .text-l6{
  font-family: graphik;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

:global .text-l7m{
  font-family: graphik;
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
}

:global .text-l4{
  font-family: graphik;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}
:global .text-l4m{
  font-family: graphik;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}

:global .text-l3m{
  font-family: graphik;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

:global .text-l3{
  font-family: graphik;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
}

:global .text-l7{
  font-family: graphik;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}
:global .text-l7m{
  font-family: graphik;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

:global .text-l8{
  font-family: graphik;
  font-size: 12px;
  line-height: 14px;
}

:global .text-l8m{
  font-family: graphik;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

:global .text-l9m{
  font-family: graphik;
  font-size: 9px;
  line-height: 12px;
  font-weight: 600;
}
