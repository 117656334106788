@value color-bg-jobrequest, color-no-result, color-red, color-profile-background, color-white, color-primary, color-grey, color-black, color-radio-border-idle from "~@properly/components/lib/common/styles/colors.module.css";
.checkout {
  height: 100%;
  width: 100%;
  background-color: color-bg-jobrequest;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.checkout__container-inner {
  padding-bottom: 20px;
}

.checkout__header {
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid color-profile-background;
  display: flex;
  justify-content: center;
}

.checkout__logo {
  width: 133px;
  height: 38px;
}

.checkout__back {
  position: absolute;
  left: 0;
  top: 50%;
  cursor: pointer;
}

.checkout__container {
  max-width: 466px;
  margin: 0 auto;
  position: relative;
}

.checkout__container--mobile {
  padding: 0 16px;
}
.checkout__container--mobile .checkout__header {
  padding: 12px 0;
}
.checkout__container--mobile .checkout__logo {
  width: 101px;
  height: 29px;
}
.checkout__container--mobile .checkout__section {
  padding: 16px 16px 0 16px;
}
.checkout__container--mobile .checkout__btn-container {
  padding-left: 4px;
  padding-right: 4px;
}
.checkout__container--mobile .checkout__input-error {
  height: 20px;
  line-height: 16px;
  font-size: 11px;
  padding-top: 0;
}

.checkout__btn-container {
  margin-top: 15px;
  padding-left: 32px;
  padding-right: 32px;
}

.checkout__btn-msg {
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  text-align: center;
}

:local(.checkout__btn-msg-error) {
  composes: input-error from global;
  color: color-red;
}

:local(.checkout__btn-msg-black) {
  composes: input-error from global;
  color: color-black;
}

.checkout__link {
  color: color-primary;
  cursor: pointer;
}

:local(.checkout__tos) {
  composes: text-l7 from global;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
}

.checkout__tos-txt {
  padding-left: 6px;
}

.checkout__input {
  visibility: visible;
  color: color-black;
  font-family: "Arial";
  font-feature-settings: normal;
  font-kerning: auto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-weight: normal;
  line-height: 100%;
  text-rendering: auto;
  text-shadow: none;
  text-transform: none;
  background: transparent;
  border: none;
  display: inline-block;
  width: 100%;
  height: 100%;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  -webkit-font-smoothing: initial;
  -moz-osx-font-smoothing: initial;
}

:local(.checkout__input-label) {
  composes: text-l8m from global;
  padding-bottom: 6px;
  display: block;
}

.checkout__input-man {
  color: color-primary;
}

.checkout__applywrap {
  padding-top: 22px;
}

:local(.checkout__property-section) {
  display: flex;
  justify-content: space-between;
  composes: text-l8 from global;
  padding-bottom: 8px;
}

:local(.checkout__property-section--bold) {
  composes: text-l8m from global;
}

:local(.checkout__property-section--big) {
  composes: text-l8m from global;
  font-size: 18px;
  line-height: 18px;
}

.checkout__property-section--grey {
  color: color-no-result;
}

.checkout__property-section-scroll {
  min-height: 155px;
  max-height: 155px;
  overflow: auto;
  border-bottom: 1px solid color-radio-border-idle;
  margin-bottom: 8px;
}

.checkout__property-section-scroll--nominheight {
  min-height: 0;
}

.checkout__row {
  display: flex;
}

.checkout__row--row {
  flex-direction: row;
}

.checkout__row--column {
  flex-direction: row;
  flex-wrap: wrap;
}

:local(.checkout__input-error) {
  composes: text-l8 from global;
  height: 32px;
  padding-top: 10px;
  color: color-red;
}

.checkout__input-error--grey {
  color: color-black;
}

.checkout__overlayloading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.checkout__overlayloading-inner {
  background: color-white;
  opacity: 0.5;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout__section {
  border: 1px solid color-radio-border-idle;
  background: color-white;
  padding: 32px 32px 0 32px;
  border-radius: 2px;
  position: relative;
  min-height: 100px;
}

:local(.checkout__section-title) {
  composes: label from global;
  color: color-black;
  font-weight: 400;
  padding: 16px 0 8px 0;
  margin: 0;
}

.checkout__modalwrap {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.checkout__modal-loading {
  height: 24px;
  margin-top: 15px;
}

.checkout__modal-txt {
  margin-top: 30px;
}

.checkout__modalwrapmobile {
  position: fixed;
  background: color-white;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding-bottom: 44px;
  padding-left: 16px;
  padding-right: 16px;
  z-index: 100;
}

.checkout__modal-btn {
  border-top: 1px solid color-radio-border-idle;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.checkout__recurlyfield--error :global(.recurly-hosted-field) {
  border: 1px solid color-red !important;
}

:global(.recurly-hosted-field) {
  position: relative;
  width: 100%;
  height: 48px;
  border: 1px solid color-radio-border-idle;
  background: transparent;
  padding: 14px 16px;
  outline: none;
  font-family: Arial;
  box-shadow: none;
  letter-spacing: 0.2px;
  color: color-black;
  border-radius: 3px;
}
:global(.recurly-hosted-field):hover {
  border: 1px solid color-grey;
}

:global(.recurly-hosted-field-focus) {
  border: 1px solid color-primary !important;
}
