@value color-bg-jobrequest from '~@properly/components/lib/common/styles/colors.module.css';

.settings__bg{
  background-color: color-bg-jobrequest;
  height: 100%;
  overflow: hidden;
}

.settings-sidebar {
  position: relative;
}

.settings-sidebar__icon {
  position: absolute;
  top: 0;
  right: 1em;
  transform: translateY(90%);
}
