@value color-bg-jobrequest, color-primary, color-table-grey, color-dark-profile, color-white, color-loading-txt from '~@properly/components/lib/common/styles/colors.module.css';
@value space-base, space-small from '~@properly/components/lib/common/styles/sizes.module.css';

.account__bg{
  background-color: color-bg-jobrequest;
  height: 100%;
  overflow: hidden;
}

.account__bg-white{
  background-color: color-white !important;

}


.account__bg-relative {
  position: relative;
}

.account__sidebar{
  padding: 18px 14px;
}

.account__row{
  display: flex;
}

.account__forgot{
  cursor: pointer;
}

.account__loading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  position: relative;
}
.account__loading-inner{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.account__loading-error-close{
  align-self: 'flex-start';
}
.account__loading-error-txt{
  padding: 0 48px;
  text-align: center;
  display: flex;
  align-items: center;
}

.account__col{
  width: 216px;
  min-width: 216px;
  margin-right: space-base;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.account__col div {
  width: 100%;
}
.account__col--start{
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
}
.account__col--maxheight{
  max-height: 48px;
}

.account__notification{
  padding: 12px 160px 12px 0;
  height: 48px;
  display: flex;
  align-items: center;
  position: relative;
}

.account__notification-right{
  position: absolute;
  right: space-small;
  top: space-small;
  bottom: space-small;
  display: flex;
}

.account__notification-block{
  width: 24px;
  margin-left: space-base;
  display: flex;
  justify-content: center;
  align-items: center;
}

.account__connectionlink--deepLink {
  color: color-primary;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 200ms ease;

}

.account__connectionlink--deepLink:hover {
  border-bottom-color: color-primary;
}

.account__section-head{
  height: 52px;
  position: relative;
  display: flex;
  align-items: center;
}
:local(.account__sub-section-head){
  color: color-dark-profile;
  font-weight: 500;
  height: 48px;
  composes: text-type-small from global;
  display: flex;
  align-items: center;
}

.account__flex{
  display: flex;
}
.account__flexright{
  display: flex;
  justify-content: flex-end;
}

.account__space{
  width: space-base;
}

.account__edit{
  width: 336px;
}

.account__flex-end{
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.account-oauth-direct-container__loading {
    background: color-white;
    margin: auto;
    max-width: 500px;
    min-height: 300px;
    position: relative;
    width: 100%;

}

.account-oauth-direct-container__wrapper{
  height: 50px;
  margin: -32px;
}

.account-oauth-direct-container__center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.account-oauth-direct-container__loadtext{
  margin-left: 10px;
  width: max-content;
  color: color-loading-txt;
}
.account-oauth-direct-container__loadcontainer{
  display: flex;
}

.accountAdapterEdit__vendor--icon{
  height: 22px;
  width: 22px;
  margin-right: 10px;
}

.account__profilepic{
  height: 156px;
  width: 156px;
  position: relative;
}

.account__flex-1 {
  flex: 1;
}
.account__flex-1:first-child {
  max-width: 50%;
  padding-right: 12px;
}
.account__flex-1:last-child {
  max-width: 50%;
  padding-left: 12px;
}
:local(.account__subscription-subtxt){
  composes: text-l5 from global;
}
:local(.account__subscription-head){
  composes: text-l5m from global;
}
:local(.account__subscription-small){
  composes: text-l7 from global;
}
:local(.account__subscription-list){
  list-style: disc;
  padding-left: 20px;
  composes: text-l7 from global;
}

:local(.account__subscription-cancel) {
  composes: text-l7 from global;
}
:local(.account__subscription-cancel) span {
  color: color-primary;
  cursor: pointer;
}

.account__subscription-space{
  height: 80px;
}
.account__subscription-spacenormal{
  height: 64px;
}
.account__subscription-spacesmall{
  height: 40px;
}

.account__subscription-mobilewrap {
  padding: 16px;
}
.account__subscription-mobilewrap .account__subscription-spacesmall {
  height: 16px;
}
.account__subscription-mobilewrap .account__subscription-spacenormal {
  height: 16px;
}
.account__subscription-mobilewrap .account__subscription-space {
  height: 16px;
}
