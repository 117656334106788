.autoComplete::placeholder {
  font-size: 1.0em;
}

.autoComplete::selection {
  color: red;
  background: yellow;
}

body {
  font-family: sans-serif;
}

.autoComplete {
  border: 1px solid #999;
  border-radius: 3px;
  padding: 1.0rem;
  width: 100%;
  font-size: 1.0em;
}

.autoComplete:focus{
  outline: 2px solid #36ACB2;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestions {
  border: 1px solid #999;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  width: calc(500px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #fff;
  color: #fae042;
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid #999;
}
