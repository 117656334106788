

.permissions__centerbtn{
    display: flex;
    align-items: center;
}
.permissions__centerbtn2{
    display: flex;
    justify-content: center;
}

.permission__infomessagewrap{
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
}

.permission__starticon{
    width: 594px;
    position: absolute;
    bottom: 0;
    left: 250px;
    height: 393px;
    transform: translateY(100%);
}
.permission__starticon--company{
    left: 330px;
}
