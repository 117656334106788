@value color-bg-jobrequest, color-model-headline, color-black, color-white, color-primary, color-grey, color-profile-background from '~@properly/components/lib/common/styles/colors.module.css';
@value space-large, space-base, space-tiny, space-small-mid from '~@properly/components/lib/common/styles/sizes.module.css';

.jobdetails__bg{
  background-color: color-bg-jobrequest;
  border-radius: inherit;
}

.jobdetails__canorange {
  color: color-orange
}

.jobdetails__section{
  position: relative;
  margin-top: space-base;
}

.jobdetails__section--small-margin {
  margin-top: space-tiny;
}

.jobdetails__section-title{
  height: space-large;
  position: relative;
  display: flex;
  align-items: center;
}

.jobdetails__date{
  display: flex;
}

.jobdetails__date-icon{
  display: flex;
  margin-left: space-tiny;
  margin-right: space-tiny;
}
.jobdetails__date-duration{
  display: flex;
  margin-left: space-tiny;
  align-items: center;
}

.jobdetails__box{
  padding: 18px 16px;
  border-radius: 2px;
  background: color-white;
  border: 1px solid rgba(29,39,48,0.12);
}
.jobdetails__box--noptb{
  padding-bottom: 0;
  padding-top: 0;
}

.jobdetails__imagegrid-row{
  display: flex;
  margin-right: -8px;
  margin-bottom: space-tiny;
}
.jobdetails__imagegrid{
  margin-bottom: -8px;
}

.jobdetails__imagegrid-image{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-color: color-profile-background;
  position: relative;
}

.jobdetails__imagegrid-image-bubble{
  position: absolute;
  bottom: 7px;
  right: 7px;
}

.jobdetails__box-image{
  height: 248px;
  width: 100%;
  background-size: cover;
}

.jobdetails__box-image:hover {
  cursor: pointer;
}

:local(.jobdetails__summary-section-inner) {
  padding-right: 65px;
  color: color-grey;
  composes: text-type-small from global;
}
:local(.jobdetails__summary-section-inner-big) {
  color: color-grey;
  composes: text-type-regular from global;
  display: flex;
  align-items: center;
}
:local(.jobdetails__summary-section-inner-text) {
  color: color-grey;
}

.jobdetails__space8{
  width: space-tiny;
}

.jobdetails__box--problems{
  margin-bottom: space-small-mid;
}

:local(.jobdetails__summary-section) {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: space-large;
  composes: text-type-regular from global;
  color: color-grey;
}
.jobdetails__summary-section-head{
  flex: 1;
  display: flex;
  align-items: center;
}
.jobdetails__summary-icon{
  padding-right: 14px;
  min-height: space-base;
  min-width: space-base;
}

.jobdetails__summary-section--big{
  justify-content: flex-start;
  height: auto;
  padding-top: space-small-mid;
  padding-bottom: space-small-mid;
}

.jobdetails__summary-section-inner--active{
  color: color-primary;
}
.jobdetails__summary-section--active{
  color: color-black;
}


.jobdetails__loading{
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jobdetails__box-title{
  margin-top: space-small-mid;
}
.jobdetails__box-title--nomargin{
  margin-top: 0;
}

.jobdetails__imagegrid-item{
  flex-basis: 33.333333%;
  max-width: 33.333333%;
  height: 74px;
  padding-right: space-tiny;
  cursor: pointer;
}

.jobdetails__flex{
  display: flex;
}
.jobdetails__fixmargin{
  margin-top: -space-base;
}

.jobdetails__flex-1{
  flex: 1;
}

.jobdetails__flex-1:first-child {
    max-width: 50%;
    margin-right: space-small-mid;
}

.jobdetails__flex-1:last-child {
  max-width: 50%;
  margin-left: space-small-mid;
}

.jobdetails__payment-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: space-large;
  color: color-grey;
}

.jobdetails__payment-section--active{
  color: color-black;
}

.jobdetails__payment-section-head{
  flex: 1;
  display: flex;
  align-items: center;
}

.jobdetails__payment-icon{
  padding-right: 14px;
  min-height: space-base;
  min-width: space-base;
}

.jobdetails__payment-container{
    padding: space-small-mid 0;
}

.jobdetails__payment-subcontainer-item  {
    min-height: 2em;
}

.jobdetails__payment-subcontainer  {
  display: flex;
  justify-content: space-between;
}

.jobdetails__payment-subcontainer-item-against-chip{
    transform: translateY(6px);
}

.jobdetails__payment-properlyPaymentsEmailLink {
  color: color-primary;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border 200ms ease;
}

.jobdetails__payment-properlyPaymentsEmailLink:hover {
  border-bottom-color: color-primary;
}

.jobdetails__payment-subcontainer-item-tooltip{
  margin-left: 2px;
  transform: translateY(-4px);
}


.jobdetails__payment-caption-info{
  display: flex;
  justify-content: center;
  margin-top: 8px;
}
