* {
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.app, :global(.app) {
  height: 100%;
  font-family: 'Graphik Web', Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-smoothing: antialiased;
}

:global(.inherit) {
  fill: currentColor;
}

:global(.clickable) {
  cursor: pointer;
}

button{
  border-radius: 0px;
}


:global(.ReactVirtualized__Grid), :global(.ReactVirtualized__List) {
  outline: none;
}

:global input[type=number]::-webkit-inner-spin-button,
:global input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Tooltip accessability */
:global(.u-accessible) {
  display: none;
}
