@value color-white, color-no-result, color-bg-jobrequest, color-primary, color-border-search, color-text, color-grey, color-background, color-black from '~@properly/components/lib/common/styles/colors.module.css';
@value space-large, space-base from '~@properly/components/lib/common/styles/sizes.module.css';


.property-detail__checklistrow{
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
  margin-top: space-base;
}

.property-detail__checklistcol{
  padding: 0 8px;
  flex: 1;
  max-width: 178px;
}

.property-detail__loadingcheck{
  height: 165px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.property-detail-header {
    display: flex;
    flex-direction: column;
    margin: -48px -32px -44px;
    padding: 64px 32px 76px;
    background: white;
}
.property-detail-address {
    flex-grow: 1;
    padding-right: 12px;
}
.property-detail-picture__container {
   margin: 0 36px 0 12px;
   display: flex;
   height: 156px;
  flex-direction: column;
  align-content: center;
  align-items: center;
}
.property-detail-picture {
    flex-shrink: 0;
    width: 120px;
    height: 120px;
    position: relative;
    display: flex;
    transition: opacity 200ms ease;
    margin-bottom: 12px;
}
.property-detail-picture:hover {
  opacity: 0.8;
}

:local(.property-detail__icaltxt){
  composes: input-error from global;
  color: color-grey;
  text-align: center;
}
.property-detail__icaltxt-link{
  color: color-primary;
  cursor: pointer;
}
:local(.property-detail__icallink){
  flex: 1;
  padding-right: 24px;
  user-select: none;
  composes: cut-txt from global;
}

.property-detail__icaltitle {
  padding-right: 24px;
}
.property-detail__icaltitle > span {
  font-weight: bold;
}

.property-detail__icaltrash{
  cursor: pointer;
}

.property-detail-picture__inner{
  top: 0;
  bottom: 0;
  left: 12px;
  right: 0;
}

:local(.property-jobs__no-result){
  display: flex;
  height: 165px;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  align-items: center;
  composes: label from global;
  color: color-no-result;
}

.property-detail__icalinputwrap{
  display: flex;
}

.property-detail__icalinputwrap-title{
  max-width: 250px;
}
.property-detail__icalinputwrap-link{
  flex: 1;
}

.property-detail-flex{
  display: flex;
}

.property-detail-flex-1 {
  flex: 1;
}
.property-detail-flex-1:first-child {
  max-width: 50%;
  padding-right: 12px;
}
.property-detail-flex-1:last-child {
  max-width: 50%;
  padding-left: 12px;
}

.property-detail-flex-2 {
  flex: 1;
}
.property-detail-flex-2:first-child {
  max-width: 50%;
  padding-right: 12px;
}

:local(.property__cctxt){
  composes: text-l7 from global;
  text-decoration: underline;
  color: color-primary;
  cursor: pointer;
  margin-top: 10px;
}

.property__ccloading{
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property__ccborder--top{
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}
.property__ccborder--bottom{
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  overflow: hidden;
}
.property__ccempty{
  background-color: color-bg-jobrequest;
  border-radius: inherit;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.property-detail__addwrap{
  border-bottom: 1px solid color-border-search;
  height: 36px;
  padding-top: 10px;
  padding-bottom: 10px;
}

:local(.property-detail__add){
  composes: input-error from global;
  color: color-primary;
  cursor: pointer;
}
:local(.property-detail__eventtxt){
  composes: text-l7 from global;
  color: color-black;
  margin-top: 12px;
}

.branding-ad-send-job {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 17rem;
}
