@value color-white:                 #fff;

/* COLORS STYLEGUIDE */
@value color-primary:               #36ACB2;
@value color-primary-hover:         #197D82;
@value color-primary-selection:     #D0F4F6;
@value color-primary-light:         #D0F4F682;

@value color-orange:                #FF5722;
@value color-orange-hover:          #E64A19;

@value color-blue:                  #00C4FF;
@value color-blue-hover:            #039BE5;

@value color-blue-accepted:         #0078FF;
@value color-blue-accepted-hover:   #005AE1;

@value color-black:                 #29333D;
@value color-black-hover:           #0C1620;

@value color-grey:                  #868F9A;
@value color-grey-hover:            #5C6670;

@value color-background:            #EFF0F1;

@value color-paper:                 #F9F9F9;

/* OTHER COLORS */
@value color-header:                #333D46;
@value color-subheader:             #455A64;
@value color-text:                  #29333D;
@value color-placeholder:           #78828B;
@value color-border:                #EAEBED;

@value color-background-dark:       #232527;
@value color-dark-inactive:         #999B9D;

@value color-line:                  #CCD1D1;

@value calendar-highlight:          #F7F7F7;

@value color-item-description:      #999B9E;

@value color-divider:               #F0F0F0;

@value color-border-search:         #E2E6E9;

@value color-button-border:         #89929D;

@value color-loading-txt:           #6B757F;

@value color-no-result:             #A0AAB4;

@value color-backdrop:              #363636;

@value color-model-headline:        #1D2730;

@value color-radio-border-idle:     #D3D6D9;

@value color-red:                   #FF3230;

@value color-red-light:             #FBE9E7;

@value color-red-light-darker:      #FFCCBC;

@value color-profile-background:    #D8D8D8;

@value color-grey-light:            #C2CCD6;

@value color-bg-jobrequest:         #FAFAFA;

@value color-light-blue:            #E9F5FF;
@value color-light-blue-cleaner:    #E3F2FD;

@value color-darker-blue:            #D4EBFC;
@value color-darker-blue-cleaner:    #1565C0;

@value color-dark-blue:             #23387E;
@value color-dark-blue-hover:       #182656;

@value color-darker-blue-line:       #BBDEFB;
@value color-account-sidebar-active: #F1F1F1;
@value color-dark-profile:           #06111D;

@value color-dark-grey:              #57575D;
@value color-dark-grey-darker:       #343538;
@value color-checklist-btn-txt:      #ABABAE;
@value color-checklist-orange:       #FFAB91;
@value color-checklist-grey:         #F6F7F8;
@value color-checklist-overlay-grey: #F3F3F3;
@value color-checklist-light-orange: #FF8A65;
@value color-checklist-text-red:     #FF8A65;

@value color-green-dark:             #6ac98f;
@value color-green-light:            #e8f7ed;
@value color-airbnb:                 #FB5E65;
@value color-light-border:           #E3E5E6;
@value color-dark-border:            #D2D4D6;
@value color-light-orange:           #FDF4F3;
@value color-dark-orange:            #C94B25;
@value color-real-black:             #000000;
@value color-green:                  #32D17D;
@value color-green-hover:            #32B95F;
@value color-orange-delayed:         #FB8C00;
@value color-orange-delayed-hover:   #EF6C00;
@value color-table-grey:             #EEEEEE;
@value color-pricing-grey:           #858E99;
@value color-pricing-divier-grey:    #979797;
@value color-grey-event:             #E5E6E7;
@value color-booking-cal:            #BDBDBD;
@value color-booking-cal-txt:        #212121;
@value color-booking-cal-cancelled:  #E0E0E0;
@value color-booking-cal-cancelled-txt:  #616161;
@value color-sidebar-section:        #242D36;
@value color-jobflow:                #F7FAFA;


/*
    job requests
*/

@value color-jr-pending: #B2EBF2;
@value color-jr-pending-hover: #80DEEA;
@value color-jr-pending-font: #006064;
@value color-jr-pending-bar: #26C6DA;
@value color-jr-pending-btn-full: #26C6DA;
@value color-jr-pending-btn-full-hover: #0097A7;
@value color-jr-accepted: #BBDEFB;
@value color-jr-accepted-hover: #90CAF9;
@value color-jr-accepted-font: #0D47A1;
@value color-jr-accepted-bar: #42A5F5;
@value color-jr-accepted-btn-full: #0096F3;
@value color-jr-accepted-btn-full-hover: #0288D1;
@value color-jr-progress: #E1BEE7;
@value color-jr-progress-hover: #CE93D8;
@value color-jr-progress-font: #4A148C;
@value color-jr-progress-bar: #7E57C2;
@value color-jr-finished: #C8E6C9;
@value color-jr-finished-hover: #A5D6A7;
@value color-jr-finished-font: #1B5E20;
@value color-jr-finished-bar: #66BB6A;
@value color-jr-delayed: #FFE0B2;
@value color-jr-delayed-hover: #FFCC80;
@value color-jr-delayed-font: #E65100;
@value color-jr-delayed-bar: #FFA726;
@value color-jr-delayed-btn-full: #FFA726;
@value color-jr-delayed-btn-full-hover: #F57C00;
@value color-jr-cancelled: #FFCDD2;
@value color-jr-cancelled-hover: #F09E9E;
@value color-jr-cancelled-font: #B71C1C;
@value color-jr-cancelled-bar: #FF3230;
@value color-jr-cancelled-btn-full: #FF3230;
@value color-jr-cancelled-btn-full-hover: #D32F2F;


/*
    responsetime
*/
@value color-responsetime-green: #43A047;
@value color-responsetime-orange: #FFA726;
@value color-responsetime-red: #FF5722;

/* branding */
@value color-step-number: #36ACB2;
@value color-step-text: #696666;
